/*
There is no import for bootstrap or tailwind or the latest new and cool CSS framework?
Nope. After many years of working with CSS/UI frameworks I wanted to experience again how it feels to start from zero. 😎
*/
@use 'sass:color';

@import './variables';
@import '@fontsource/quicksand/300.css';

@mixin spacing($factor) {
  --space-#{$factor}: calc(#{$space} * #{$factor});
}

/*
color vars
*/

:root {
  --col-bg: #{$black};
  --col-text: #{$white};
  --col-bg-light: #{$darker-grey};
  --col-primary: #{$cyan};
  --col-text-on-primary: #{$black};
  --col-primary-highlight: #{$cyan-dark};
  --col-text-on-primary-highlight: #{$white};
  --col-shadow: color-mix(in srgb, #{$black}, transparent 50%);
}

[data-theme='light'] {
  --col-bg: #{$lighter-grey};
  --col-text: #{$black};
  --col-bg-light: #{$white};
  --col-primary: #{$cyan-dark};
  --col-text-on-primary: #{$white};
  --col-primary-highlight: #{$cyan};
  --col-text-on-primary-highlight: #{$black};
  --col-shadow: color-mix(in srgb, #{$dark-grey}, transparent 80%);
}

/*
ui vars
*/
:root {
  @for $i from 1 through 8 {
    @include spacing($i);
  }
  --transition-duration-base: 0.3s;
  --anim-ease-func: cubic-bezier(0.2, 0.9, 0.3, 1.2);
  --font-family: 'Quicksand';
  --icon-size-base: 1.5rem;
}

/*
basic styles
*/
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: $black;
}

.app {
  background-color: var(--col-bg);
  font-family: var(--font-family);
  color: var(--col-text);
  display: flex;
  flex-direction: column;
  height: 100vh;
}

a {
  color: var(--col-primary);
  text-decoration: none;
  &:hover {
    color: var(--col-text);
  }
}

@import './theme/typography';

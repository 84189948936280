@import 'Styles/mixins';

.button {
  padding: var(--space-1) var(--space-2);
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  gap: var(--space-1);
}

.default {
  background-color: var(--col-primary);
  color: var(--col-text-on-primary);
  &:hover {
    background-color: var(--col-primary-highlight);
    color: var(--col-text-on-primary-highlight);
  }
}

.border {
  background: transparent;
  outline-style: solid;
  outline-width: 1px;
  outline-color: var(--col-primary);
  outline-offset: -1px;
  color: var(--col-primary);
  &:hover {
    outline-color: var(--col-text);
    color: var(--col-text);
  }
}

.link {
  padding: 0;
  background: transparent;
  color: var(--col-primary);
  &:hover {
    color: var(--col-text);
  }
}

.icon svg {
  height: var(--icon-size-base);
}

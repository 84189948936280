@import 'Styles/mixins';

.page {
  @include container-full();
  flex: 1;
}

.pageContent {
  @include box-shadow();
  background-color: var(--col-bg-light);
  padding: var(--space-4);
  margin: 0 var(--space-4);
  overflow: scroll;
}

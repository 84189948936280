:root {
  --height-h1: 1.8rem;
}

p,
h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: var(--space-2);
}

h1 {
  font-size: var(--height-h1);
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.4rem;
}

hr {
  margin: var(--space-2) 0;
  border: none;
}

@import './variables';

@mixin container-full {
  width: 100%;

  @media (min-width: $breakpoint-sm) {
    max-width: $breakpoint-sm;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: $breakpoint-md) {
    max-width: $breakpoint-md;
  }

  @media (min-width: $breakpoint-lg) {
    max-width: $breakpoint-lg;
  }
}

@mixin flex {
  display: flex;
  gap: var(--space-4);
  align-items: center;
}

@mixin flex-sm {
  @include flex;
  gap: var(--space-2);
}

@mixin box-shadow {
  box-shadow: 0 3px 10px var(--col-shadow);
}

@mixin unbutton {
  background: transparent;
  border: none;
  width: 100%;
  text-align: left;
  padding: 0;
  color: var(--col-primary);
  font-family: var(--font-family);
  font-size: medium;
}

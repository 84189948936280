@import 'Styles/variables';

.grid {
  display: flex;
  gap: 1px;
  flex-direction: column-reverse;

  max-width: 38rem;
  margin: 0 auto 4rem auto;
  @media (min-width: $breakpoint-md) {
    flex-direction: row;
  }
  & > a {
    flex: 1;

    &:hover {
      z-index: 10; // prevents strange looking overlap during transition
    }
  }
}

.logo {
  max-width: 100%;
}

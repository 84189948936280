@import 'Styles/variables';
@import 'Styles/mixins';

.outer {
  @include container-full;
}

.notice {
  background: var(--col-bg-light);
  padding: var(--space-4);
  margin: var(--space-4);
  font-size: small;
}

.buttons {
  @include flex-sm;
}

@use 'sass:map';
@use 'sass:color';

// Colors
$white: #fff;
$black: #000;
$cyan: #00d1ff;
$cyan-dark: #1088a3;
$magenta: #fa0862;
$light-grey: #b4a9bb;
$dark-grey: #433d51;
$lighter-grey: mix($white, $light-grey, 70%);
$darker-grey: mix($dark-grey, $black, 60%);

// Sizes for layout
$fontsize-base: 14px;
$space: 0.5rem;
$breakpoint-sm: 480px;
$breakpoint-md: 768px;
$breakpoint-lg: 1280px;

// other cool stuff
$transitionFast: var(--transition-duration-base) var(--anim-ease-func);
$transitionMedium: calc(var(--transition-duration-base) * 2) var(--anim-ease-func);

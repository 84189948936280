@import '/src/Styles/mixins';
@import '/src/Styles/variables';

.imageButton {
  transition: scale $transitionFast;
  flex: 1;
  background-color: var(--col-primary);
  color: var(--col-text-on-primary);
  overflow: visible;
  scale: 1;
  margin-top: 4rem;

  &:hover,
  &:focus-visible {
    @include box-shadow;
    scale: 1.05;
    color: var(--col-text);
    background-color: var(--col-primary-highlight);
    z-index: 10;
    .image {
      bottom: 5rem;
    }
    .title {
      background-color: var(--col-primary-highlight);
      svg {
        fill: var(--col-text);
      }
    }
  }
}

.imageArea {
  aspect-ratio: 1;
  width: 100%;
  background-color: var(--col-shadow);
}

.image {
  transition: bottom $transitionFast;
  position: absolute;
  bottom: 4rem;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.title {
  position: relative;
  top: 0;
  text-align: center;
  padding: var(--space-4);
  font-size: var(--height-h1);
  background-color: var(--col-primary);

  svg {
    height: var(--height-h1);
    fill: var(--col-text-on-primary);
  }
}

.subtitle {
  font-size: small;
}

@import 'Styles/mixins';

.outer {
  @include box-shadow;
  background-color: var(--col-bg-light);
  padding: var(--space-4) var(--space-4);
  @include flex;
  justify-content: space-between;
  @media (min-width: $breakpoint-md) {
    @include flex;
  }
  align-items: flex-start;
}

.nav {
  width: 100%;
  @media (min-width: $breakpoint-md) {
    @include flex;
  }

  ul {
    @include flex;
    padding: 0;
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  h1 {
    margin: 0;
  }

  hr {
    display: none;
    @media (min-width: $breakpoint-md) {
      display: block;
      border-right: 1px solid var(--col-text);
      height: var(--height-h1);
      margin: 0;
    }
  }
}

.navBrand {
  flex-grow: 1;

  h1 {
    margin-bottom: var(--space-4);
    @media (min-width: $breakpoint-md) {
      margin-bottom: 0;
    }
  }

  svg {
    height: calc(var(--height-h1) + 0.4rem);
    margin-bottom: -0.4rem;
    width: auto;
    fill: var(--col-primary);
  }

  a:hover svg {
    fill: var(--col-text);
  }
}

.toggle {
  svg {
    margin-top: 0.5rem;
    width: var(--icon-size-base);
    height: var(--icon-size-base);

    @media (min-width: $breakpoint-md) {
      margin-top: 0;
    }
  }
}

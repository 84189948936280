@import 'Styles/mixins';

$margin-lg: var(--space-8);

.container {
  @include container-full();
}

.nav {
  margin: $margin-lg $margin-lg 0 $margin-lg;
  ol {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: small;
    margin: var(--space-1) 0;
  }

  li {
    &:not(:last-child) {
      padding-right: var(--space-2);
    }
    &:not(:first-child) {
      border-left: 1px solid var(--col-text);
      padding-left: var(--space-2);
    }
  }
}

@import 'Styles/mixins';

$iconSize: 2.2rem;

.outer {
  padding: var(--space-4) 0;
  border-bottom: 1px solid var(--col-text);

  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
}
.header {
  @include flex;
  @include unbutton;
  font-size: large;
  cursor: pointer;

  &:hover,
  &:focus-visible {
    color: var(--col-text);
  }
}
.question {
  flex: 1;
  margin: 0;
}
.icon {
  height: 0rem;
  margin-top: -$iconSize;
  margin-right: -$iconSize * 0.25;
  > svg {
    transition: rotate $transitionFast;
    height: $iconSize;
  }
}
.answer {
  overflow: hidden;
  transition:
    height $transitionFast,
    margin-top $transitionFast;
  margin-top: var(--space-2);
  padding-right: $iconSize;
}

@import 'Styles/mixins';

.footer {
  @include box-shadow();
  background-color: var(--col-bg-light);
  padding: var(--space-2) var(--space-4);
  font-size: small;
  @include flex();
  margin-top: var(--space-4);
}

@import 'Styles/mixins';

.outer {
  @include container-full;
}

.content {
  margin: var(--space-4);
  padding: var(--space-4);
}

.cookies {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cookie {
  animation-name: spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}

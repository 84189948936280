@import 'Styles/mixins';
@import 'Styles/variables';

$innerMargin: var(--space-2);

.grid {
  @include flex();
  flex-direction: column;
}

.card {
  @include box-shadow();
  padding: var(--space-4);
  width: 100%;

  h2 {
    margin-bottom: $innerMargin;
  }
}

.layout {
  @include flex;
  flex-direction: column;
  align-items: start;

  @media (min-width: $breakpoint-md) {
    flex-direction: row;
  }
}

.thumbnail {
  width: 100%;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-size: cover;
}

.icon {
  width: 80%;
}

.info {
  flex: 1;
}

.description {
  margin-bottom: $innerMargin;
}
